<template>
    <div class="cam-pic">
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item title="筛选条件" class="filter">
            <van-cell title="请选择开始时间" :value="filter.time.startVal" is-link @click="filter.time.startShow = true"/>
            <van-cell title="请选择结束时间" :value="filter.time.endVal" is-link @click="filter.time.endShow = true"/>
            <!--筛选告警类型-->
            <van-cell title="按设备查询" class="line-cell" :value="filter.devInfo.devCode" is-link @click="filter.devInfo.showPicker = true"/>
            <van-row class="text-center">
                <van-col span="12"><van-button type="primary" size="small" @click="resetData">重置</van-button></van-col>
                <van-col span="12"> <van-button type="primary" size="small" @click="filterData">筛选</van-button></van-col>
            </van-row>
        </van-collapse-item>
      </van-collapse>
      <div class="glo-content glo-magbot">
          <div v-if="listLoading"><van-loading type="spinner" vertical>加载中</van-loading></div>
          <div v-else>
              <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getPictureList" :offset="1" :immediate-check="false">
                  <div v-if="infoList.length == 0">
                      <van-empty description="没有符合数据" />
                  </div>
                  <div v-else>
                      <van-col span="12" v-for="(item,index) in infoList" :key="index" class="block" @click="previewImg(item.filePath)">
                        <div class="logo"><img :src="item.filePath"></div>
                        <div class="title" :class="{ 'triggerEvent':item.eventType === '1' ,'nomalEvent':item.eventType !== '1'}">{{item.uploadTime}}</div>
                      </van-col>
                  </div>
              </van-list>

            <p v-if="!finished && !loading" class="load-more" @click="getPictureList">查看更多</p>
          </div>
      </div>
      <!-- 筛选区域 -->
      <van-popup v-model="filter.devInfo.showPicker" round position="bottom">
          <van-picker show-toolbar :columns="filter.devInfo.columns" @cancel="filter.devInfo.showPicker = false" @confirm="areaConfirm" />
      </van-popup>
      <!--筛选触发类型-->
    <!-- <van-popup v-model="filter.trigType.showPicker" round position="bottom">
      <van-picker show-toolbar :columns="filter.devCode.columns" @cancel="filter.devInfo.showPicker = false" @confirm="trigTypeConfirm" />
    </van-popup> -->
      
      <!-- 筛选时间段 -->
      <van-popup v-model="filter.time.startShow" round position="bottom">
        <van-datetime-picker
          v-model="filter.time.start"
          type="datetime"
          title="选择开始时间"
          @confirm="confromStartTime"
          @cancel="filter.time.startShow = false"
        />
      </van-popup>
      <van-popup v-model="filter.time.endShow" round position="bottom">
        <van-datetime-picker
          v-model="filter.time.end"
          type="datetime"
          title="选择结束时间"
          @confirm="confromEndTime"
          @cancel="filter.time.endShow = false"
        />
      </van-popup>
      <tabBar/>
    </div>
  </template>
  <script>
  import tabBar from "@/components/aicamera/tabbar.vue";
  import moment from "moment";
  import { ImagePreview } from 'vant';
  
  export default {
    components: {
      tabBar,
    },
    props: ['magbot'],
    data() {
      return {
        activeName: '',
        infoList:[],
        info:{
                devCode:'',
                devName:'',
                devStatus:'offline'
            },
        filter:{
          devInfo:{
            devCode: null,
            value:'',
            showPicker:false,
            columns:[]
          },
          time:{
            start:new Date(),
            end:new Date(),
            startVal:'',
            endVal:'',
            startShow:false,
            endShow:false,
          }
        },
        pageNum:1,
        pageSize:60,
        loading:false,
        finished:false,
        listLoading:true,
        alarmtotal:'',
        magbotVal:'',
        projectCode:'',
      }
    },
    watch: {
        magbot: function (val) {   
          this.magbotVal = val;   
        }
    },
    methods:{
        // 获取设备基础信息
        // getBaseInfo(){
        //     this.info = window.sessionStorage.getItem('dev-info')? JSON.parse(window.sessionStorage.getItem('dev-info')) : {};
        //     window.document.title = this.info.devName;
        // },
      // 确认开始时间
      confromStartTime(val){
        this.filter.time.startVal = moment(val).format("yyyy-MM-DD HH:mm:ss");
        this.filter.time.startShow = false;
      },
      // 确认结束时间
      confromEndTime(val){
        this.filter.time.endVal = moment(val).format("yyyy-MM-DD HH:mm:ss");
        this.filter.time.endShow = false;
      },
      // 筛选监测区域确认事件
      areaConfirm(v){
        this.filter.devInfo.showPicker = false;
        this.filter.devInfo.value = v.text;
        this.filter.devInfo.devCode = v.devCode;
      },
      // 筛选触发类型确认事件
      trigTypeConfirm(v){
      this.filter.trigType.showPicker = false;
      this.filter.trigType.value = v.text;
      this.filter.trigType.trigTypeCode = v.trigTypeCode;
    },
      // 重置
      resetData(){
        this.filter.devInfo.value = '';
        this.filter.devInfo.devCode = '';
        this.filter.time.startVal = '';
        this.filter.time.endVal = '';
        this.$refs.time.reset();
      },
      // 筛选
      filterData(){
        this.listLoading = true;
        this.finished = false;
        this.activeName = '';
        this.infoList = [];
        this.pageNum = 1;
        this.getPictureList();
      },
      // 获取设备列表
      devList(){
        this.$api.AICAMERA.getAllDev({projectCode:this.projectCode,devType:"AiCamera"}).then( d =>{
          console.log(d);
          this.filter.devInfo.columns = d;
          this.filter.devInfo.columns.forEach( e =>{
            e.text = e.devName;
          })
        })
      },
      // 加载图片信息列表
      getPictureList(){
        if(!this.projectCode){
            return;
        }
        if(this.projectCode == ''){
            return;
        }
        const data = {
          projectCode:this.projectCode,
          devType:this.info.devType,
          beginTime:this.filter.time.startVal ? this.filter.time.startVal : void 0,
          endTime:this.filter.time.endVal ? this.filter.time.endVal : void 0,
          devCode: this.filter.devInfo.devCode ,
        }
        
          // 获取设备基础信息
          this.$api.AICAMERA.filePathPage(this.pageNum,this.pageSize,data).then( d =>{
            if(Array.isArray(d)){
              this.listLoading = false;
              this.loading = false;
              this.infoList =this.infoList.concat(d);
              this.pageSize > d.length ? this.finished = true : void 0;
              this.pageNum ++;
            }
          })
        
        
      },
      previewImg(url){
        ImagePreview([url]);
      }
    },
    created(){
      this.projectCode = window.sessionStorage.getItem("projectCode");
      // this.getBaseInfo();
    },
    mounted(){
      var dateTime = new Date();
      this.filter.time.endVal = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
      var dateTime1 = dateTime.setDate(dateTime.getDate()-1);
      dateTime1=new Date(dateTime1);
      this.filter.time.startVal = moment(dateTime1).format("yyyy-MM-DD HH:mm:ss");
      this.devList();
      this.getPictureList();
    }
  }
  </script>
  <style lang="scss" scoped>
  .filter{text-align: left;}
  .van-button-primary{
    background-color: #57c3c2;
    
  }
  .cam-pic{
    // background-image: linear-gradient(141deg,#affbda 0%,#1fc8db 51%,#2cb5e8 75%);
    // background: linear-gradient(141deg, #33bccc 51%, #4196bf 75%);
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    overflow-y: auto;
  .block{
        position: relative;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 2px;
        width: calc(50% - 10px);
    // border: 1px solid #651f1f;
    // background-color: #3d6561f2;
    // text-align: left;font-size: 0.8rem;padding: 2%;border-radius: 10px;color: #fff;margin-bottom: 3%;
    // box-shadow: 1px 1px #dedede;
      .logo{
            img{
                width: 100%;
                height: 100%;
                vertical-align: middle;
                height: 118px;
            }
            .img-0{
                height: calc(216px + 10px);
            }
        }
        .title{
            position: absolute;
            bottom: 0;
            width: 100%;
            color: #e4e4e4;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            font-size: .65rem;
            line-height: 2;
        }
        .nomalEvent{
          background-color: #323548a3;
        }
        .triggerEvent{
          background-color: #f62828a3;
        }
  }
  .load-more{
    font-size: small;
    margin-top: 10%;
  }
}
  .text-center{
    text-align: center;
    button{
      padding: 0 20px;
      margin-top: 5%;
    }
  }
  </style>